<template>
<loader v-bind="{ loading }" text="Loading">
  <columns>
    <column>
       <page-heading
          heading="Create a Team"
      />
    </column>
    <column class="is-narrow">
      <router-link :to="{ name: 'teams' }">Back</router-link>
    </column>
  </columns>
  <form>
    <columns>
      <column>
        <p class="has-text-grey">
          Teams are given unique names (across your entire account) and must be assigned to a branch within your company. 
          It is important to note that your users (non admins) will only ever see customers, work orders or 
          locations that are associated with the branch or branches that they have been assigned to.
        </p>
      </column>
      <column class="is-narrow">
          <action-button
            @click="openNewBranchModal"
            class="is-small is-rounded"
            left-icon="plus">
            Branch
          </action-button>
        </column>
    </columns>
    <columns>
      <column>
        <text-input
          classes="is-medium is-rounded"
          :error="$root.errors.name"
          :value="team.name"
          @input="updateName"
          required>
          Name
        </text-input>
      </column>
      <column>
        <data-selector
          ref="branchSelector"
          classes="is-medium is-rounded"
          :items="branch_list"
          value-key="uuid"
          label-key="name"
          :error="$root.errors.branch_uuid"
          :value="team.branch_uuid"
          @input="branchWasSelected"
          required>
          Branch
        </data-selector>
      </column>
    </columns>
    <columns>
      <column>
        <h3 class="title is-4">Team Members</h3>
        <p class="has-text-grey">
          Whether a Team has one member or many members they should always have a leader selected.
        </p>
      </column>
      <column class="is-narrow">
        <action-button
          @click="openNewUserModal"
          class="is-small is-rounded"
          left-icon="plus">
          User
        </action-button>
      </column>
    </columns>
    <columns>
      <column class="is-4">
        <data-selector
          classes="is-medium is-rounded"
          searchable
          :items="branch_user_list"
          value-key="uuid"
          label-key="full_name"
          :error="$root.errors.team_lead_uuid"
          :value="team.team_lead_uuid"
          @input="setTeamLead"
          required>
          Team Lead
        </data-selector>
        <p class="help is-danger" v-if="!team.branch_id">
          You must select a branch before selecting the team leader
        </p>
      </column>
    </columns>
    <columns>
      <column>
        <data-selector
          classes="is-medium is-rounded"
          tag-class="is-medium is-rounded"
          delete-button-class="is-medium is-rounded is-danger"
          multiple
          searchable
          :items="branch_user_list"
          value-key="uuid"
          label-key="full_name"
          :error="$root.errors.users"
          :value="team.users"
          @input="updateMembers"
          required>
          Members
        </data-selector>
      </column>
    </columns>
    <columns>
      <column>
        <submit-button
          class="is-medium is-rounded"
          :working="creating"
          @submit="createTeam">
          Create Team
        </submit-button>
      </column>
    </columns>
  </form>
</loader>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { branch as backend } from '@/api'
import { findIndex } from 'lodash'
import { createTeamUser, createTeamBranch } from '@/modals'
import find from 'lodash/find'

export default {

  data: () => ({
    loading: true,
    creating: false,
    branch_user_list: []
  }),

  async beforeCreate() {
    await this.$store.dispatch('branch/loadList')
    this.loading = false
  },

  beforeDestroy() {
    this.clearTeam()
  },

  methods: {
    ...mapMutations('team', [
      'updateName',
      'clearTeam',
      'updateBranch',
      'updateMembers',
      'updateTeamLead'
    ]),
    setTeamLead(uuid) {
      if(!this.teamLeadIsAlreadyAMember(uuid)) {
        this.updateMembers(this.team.users.push(this.branch_user_list.find(user => user.uuid === uuid)))
      }
      this.updateTeamLead(uuid)
    },
    teamLeadIsAlreadyAMember(userId) {
      return findIndex(this.team.users, user => user.uuid === userId) > -1
    },
    branchWasSelected(branchUuid) {
      this.updateBranch(branchUuid)
      this.$store.commit('team/updateMembers', [])
      this.$refs.branchSelector.resetItems()
      this.$store.commit('team/updateTeamLead', '')
      if (branchUuid) {
        return backend.loadUserList(branchUuid, ({data}) => {
          this.branch_user_list = data
        }, () => {})
      }
    },
    createTeam() {
      this.creating = true
      this.$store.dispatch('team/create').then(() => {
        this.$toast.success('Team successfully created')
        this.$router.push({ name: 'teams' })
        this.creating = false
      }).catch(() => this.creating = false)
    },
    async openNewUserModal() {
      if(!this.team.branch_id) {
        return this.$toast.error('Please select a Branch before trying to create a new user.')
      }

      createTeamUser(this.activeBranch).then((user) => {
        if(user) {
          this.branch_user_list.push(user)
          if(!this.team.team_lead_uuid) {
            this.updateTeamLead(user.uuid)
          }
          this.updateMembers([user, ...this.team.users])
        }
      })
    },
    async openNewBranchModal() {

      createTeamBranch().then((branch) => {
        if(branch) {
          this.$store.commit('branch/addBranch', branch)
          this.updateBranch(branch.uuid)
        }
      })
    }
  },

  computed: {
    ...mapGetters('team', [
      'team'
    ]),
    ...mapGetters('branch', [
      'branch_list'
    ]),
    activeBranch() {
      return find(this.branch_list, branch => branch.uuid === this.team.branch_uuid)
    }
  }

}
</script>